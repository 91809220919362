"use client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { usePathname } from "next/navigation";

// Create the context
const CSRFContext = createContext<{
  csrf: string;
  csrfJwt: string;
}>({
  csrf: "",
  csrfJwt: "",
});

// Create the provider component
export default function CSRFProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [csrf, setCsrf] = useState<string>("");
  const [csrfJwt, setCsrfJwt] = useState<string>("");
  const pathName = usePathname();

  async function fetchNewCSRF() {
    const response = await fetch("/api/csrf/generate?pathName=" + pathName);
    const data = await response.json();
    setCsrfJwt(data);
  }

  useEffect(() => {
    async function fetchCSRF() {
      const response = await fetch("/api/csrf/get");
      const data = await response.json();

      setCsrf(data.value);
    }

    fetchCSRF();
  }, []);

  useEffect(() => {
    if (pathName && csrf) {
      fetchNewCSRF();
    }
  }, [pathName, csrf]);

  return (
    <CSRFContext.Provider value={{ csrf, csrfJwt }}>
      {children}
    </CSRFContext.Provider>
  );
}

// Custom hook to use the CSRFContext
export const useCSRF = () => {
  const context = useContext(CSRFContext);
  return context;
};
